import { PropsWithChildren } from "react";

const MessageItem = ({ type }: PropsWithChildren<{ type: 'me' | 'other' }>) => {
    return (
        <>
            <div className={`${type === 'me' ? 'justify-end' : ''} m-[6px]`}>
                <div className={`flex w-full`}>
                    {type === 'other' &&
                        <div>
                            <img className="rounded-full w-10" src="/static/media/avatar4.54d5c1de851c273b2cd9.png" />
                        </div>
                    }
                    <div className="ml-2">
                        <div className="text-[0.75rem] leading-[1rem] mb-[6px]">
                            {type === 'other' && 'John Deo'}
                        </div>
                        <div className={`${type === 'me' ? 'bg-[#E8EFFF]' : 'bg-[#DAE5FF]'} text-[0.875rem] leading-[1.334rem] p-[8px] mb-[16px] max-w-[320px] rounded-[7px]`}>
                            Message
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MessageItem;