const ChatRoomItem = () => {
    return (
        <>
            <div className="flex">
                <div>
                    <img src="/static/media/avatar4.54d5c1de851c273b2cd9.png" className="w-12" />
                </div>
                <div>
                    <h3>
                        John Deo
                        <small className="block">Marketing Manager</small>
                    </h3>
                </div>
            </div>
        </>
    )
}

export default ChatRoomItem;