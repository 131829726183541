import ChatRoomItem from "./components/ChatRoomItem";
import MessageItem from "./components/MessageItem";

const Slack = () => {
  return (
    <div>
      <div className="flex mt-5 h-full w-[800px] h-[800px]">
        <div className="max-h-[800px] w-[350px]">
          <div className="flex">
            <div>
              <img src="/static/media/avatar4.54d5c1de851c273b2cd9.png" className="w-12" />
            </div>
            <div>
              <h3>
                John Deo
                <small className="block">Marketing Manager</small>
              </h3>
            </div>
          </div>

          <div>
            <input type="text" />
          </div>

          <ChatRoomItem />

          <ChatRoomItem />
        </div>

        <div className="max-h-[800px] w-full">
          <div>
            <div className="flex">
              <div>
                <img src="/static/media/avatar4.54d5c1de851c273b2cd9.png" className="w-12" />
              </div>
              <div>
                <h3>
                  John Deo
                  <small className="block">Marketing Manager</small>
                </h3>
              </div>
            </div>
          </div>

          <div className="overflow-y-scroll h-full m-[10px] content-end">

            <MessageItem type={'me'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />
            <MessageItem type={'other'} />

          </div>

          <div className="p-[16px]">
            <input className="bg-inherit" type="text" placeholder="Type a Message" />
          </div>
        </div>

      </div>


    </div>

  );
};

export default Slack;
